var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "fullscreen": _vm.isMobile,
      "height": _vm.sideCardHeight,
      "width": _vm.sideCardWidth,
      "title": _vm.$t('cost.costs'),
      "loading": _vm.isCostsLoading
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    style: "height: calc(".concat(_vm.sideCardHeight, " - 74px - 50px); overflow-y: auto;")
  }, [_vm._l(_vm.costs, function (cost, index) {
    return _c('div', {
      key: 'refs-cost' + cost.id
    }, [_c('refs-cost', {
      attrs: {
        "item": cost
      },
      on: {
        "openDetail": function openDetail($event) {
          return _vm.goToCostDetail($event);
        }
      }
    }), index !== _vm.costs.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), _c('div', {
    staticClass: "ma-5 mb-3 mt-1"
  }, [_c('v-btn', {
    attrs: {
      "disabled": false,
      "block": "",
      "elevation": 0,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.openCostDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]), _c('cost-create-dialog', {
    ref: "costDialog",
    attrs: {
      "isDialogActive": _vm.isCostDialogActive,
      "fullscreen": true,
      "hideButton": true,
      "partnerId": _vm.document.partnerId,
      "refs": []
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCostDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCostDialogActive = $event;
      }
    }
  })], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }