var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [!_vm.isLoadingAllPartner ? _c('partner-list-card', {
          on: {
            "isLoadingPartner": _vm.setLoadingSelectedPartner
          }
        }) : _c('v-skeleton-loader', {
          staticClass: "mx-auto",
          attrs: {
            "type": "divider, card-heading, divider, list-item-two-line,list-item-two-line, divider,list-item-two-line,list-item-two-line, divider,list-item-two-line,list-item-two-line, divider,list-item-two-line,list-item-two-line, divider,list-item-two-line,list-item-two-line, divider"
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('partner-detail-card', {
          attrs: {
            "isLoading": _vm.isLoadingSelectedPartner
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }