var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("navigation.ThePartnerList.setting.templates")))]), _c('v-spacer'), _c('template-detail-create-dialog', {
    attrs: {
      "partnerId": _vm.partnerId
    },
    on: {
      "onCreated": _vm.refresh
    }
  }), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.refresh
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1)], 1), _c('v-card', {
    staticClass: "flex-container",
    attrs: {
      "flat": ""
    }
  }, [_c('filter-card-pagination', {
    attrs: {
      "filterList": _vm.filterList,
      "filter": _vm.filters,
      "search": _vm.search,
      "predefinedFilter": _vm.predefinedFilter
    },
    on: {
      "update:filter": function updateFilter($event) {
        _vm.filters = $event;
      },
      "update:search": function updateSearch($event) {
        _vm.search = $event;
      }
    }
  }), _c('div', {
    staticClass: "scrollable"
  }, [_c('v-list', {
    attrs: {
      "two-line": "",
      "item-height": 65
    }
  }, [_vm.templates.length !== 0 ? _c('v-list-item-group', {
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.templates, function (item) {
    return _c('v-list-item', {
      key: item.key + item.meta.language,
      attrs: {
        "three-line": ""
      },
      on: {
        "click": function click($event) {
          return _vm.set(item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.meta.title))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(item.meta.description || item.content ? item.content.subject : "") + " ")])], 1), _c('v-list-item-action', [item.meta.language ? _c('v-list-item-action-text', [_vm._v(" " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item.meta.language)) + " ")]) : _vm._e(), _c('v-list-item-action-text', [_vm._v(_vm._s(_vm._f("simpleDate")(item.timestamp.created)))])], 1)], 1);
  }), 1) : _vm._e()], 1), _vm.templates.length === 0 ? _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.emptyDataSrc,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }], null, false, 974937243)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportList.noData")))])])], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }