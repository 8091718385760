var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('ticket-create-form', {
    attrs: {
      "partnerId": _vm.partnerId,
      "isTicketFormValid": _vm.isTicketFormValid,
      "isTicketCustomFieldsValid": _vm.isTicketCustomFieldsValid,
      "isReferencesValid": _vm.isReferencesValid,
      "hideTitle": false,
      "titleSuggestions": _vm.titleSuggestions,
      "hideBody": false,
      "hideTags": true,
      "hideAssignees": true,
      "hideDue": true,
      "hideProject": true,
      "hideReferences": true
    },
    on: {
      "update:isTicketFormValid": function updateIsTicketFormValid($event) {
        _vm.isTicketFormValid = $event;
      },
      "update:is-ticket-form-valid": function updateIsTicketFormValid($event) {
        _vm.isTicketFormValid = $event;
      },
      "update:isTicketCustomFieldsValid": function updateIsTicketCustomFieldsValid($event) {
        _vm.isTicketCustomFieldsValid = $event;
      },
      "update:is-ticket-custom-fields-valid": function updateIsTicketCustomFieldsValid($event) {
        _vm.isTicketCustomFieldsValid = $event;
      }
    },
    model: {
      value: _vm.ticket,
      callback: function callback($$v) {
        _vm.ticket = $$v;
      },
      expression: "ticket"
    }
  }), _c('ticket-create-form', {
    staticClass: "mt-n1",
    attrs: {
      "partnerId": _vm.partnerId,
      "isTicketFormValid": _vm.isTicketFormValid,
      "isTicketCustomFieldsValid": _vm.isTicketCustomFieldsValid,
      "isReferencesValid": _vm.isReferencesValid,
      "hideTitle": true,
      "hideBody": true,
      "hideTags": false,
      "hideAssignees": false,
      "hideDue": false,
      "hideProject": false,
      "hideReferences": true
    },
    on: {
      "update:isTicketFormValid": function updateIsTicketFormValid($event) {
        _vm.isTicketFormValid = $event;
      },
      "update:is-ticket-form-valid": function updateIsTicketFormValid($event) {
        _vm.isTicketFormValid = $event;
      },
      "update:isTicketCustomFieldsValid": function updateIsTicketCustomFieldsValid($event) {
        _vm.isTicketCustomFieldsValid = $event;
      },
      "update:is-ticket-custom-fields-valid": function updateIsTicketCustomFieldsValid($event) {
        _vm.isTicketCustomFieldsValid = $event;
      }
    },
    model: {
      value: _vm.ticket,
      callback: function callback($$v) {
        _vm.ticket = $$v;
      },
      expression: "ticket"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('ticket-refs-select', {
    attrs: {
      "partnerId": _vm.partnerId,
      "listMode": true,
      "isConfirmable": false,
      "hideEditButton": true
    },
    model: {
      value: _vm.ticket.refs,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "refs", $$v);
      },
      expression: "ticket.refs"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }