var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-docphant', [_c('v-container', {
    staticClass: "pa-0",
    attrs: {
      "id": "cardContainer"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "4",
      "md": "4"
    }
  }, [_c('v-container', {
    staticClass: "manufacturerDetailContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('ManufacturerDetailCard', {
    attrs: {
      "manufacturer": _vm.manufacturer
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "8",
      "lg": "8",
      "md": "8"
    }
  }, [_c('v-container', {
    staticClass: "manufacturerDetailContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('MachineListCard', {
    attrs: {
      "machineList": _vm.machines,
      "manufacturer": _vm.manufacturer,
      "machineAttachmentList": _vm.machineAttachments
    }
  }), _c('MachineAttachmentListCard', {
    attrs: {
      "machineAttachmentList": _vm.machineAttachments,
      "manufacturer": _vm.manufacturer
    }
  })], 1)], 1)], 1)], 1), _c('DocphantFooter', {
    attrs: {
      "partner": _vm.partner,
      "id": "footer"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }