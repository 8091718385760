import { render, staticRenderFns } from "./BarChartCard.vue?vue&type=template&id=31b2fd8b&scoped=true&"
import script from "./BarChartCard.vue?vue&type=script&lang=ts&"
export * from "./BarChartCard.vue?vue&type=script&lang=ts&"
import style0 from "./BarChartCard.vue?vue&type=style&index=0&id=31b2fd8b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b2fd8b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})
