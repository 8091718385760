var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "title": _vm.$t('components.partner.PartnerReportInitializeCard.sendMail.title'),
      "subtitle": _vm.$t('components.partner.PartnerReportInitializeCard.description')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }, {
      key: "card-actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setNextStep(_vm.PagesEnum.CHOICE);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.back")) + " ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "outlined": "",
            "color": "info"
          },
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.sendMail.next")) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', [_c('partner-report-initialize-card', {
    ref: "initializeCard",
    attrs: {
      "initReport": _vm.initReport
    },
    on: {
      "update": function update(v) {
        return _vm.url = v;
      }
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mr-4 ml-4",
    attrs: {
      "auto-grow": "",
      "outlined": "",
      "readonly": "",
      "value": _vm.url,
      "rows": "1",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.result')
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }