var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "loading": _vm.loading,
      "title": _vm.$t('components.partner.PartnerReportInitializeDialogFromDa.title'),
      "subtitle": _vm.$t('components.partner.PartnerReportInitializeDialogFromDa.description')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabs, function (item) {
    return _c('v-tab', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    key: "0"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "loading": _vm.loading,
      "outlined": "",
      "append-outer-icon": "mdi-magnify",
      "label": _vm.$t('components.partner.PartnerReportInitializeDialogFromDa.customer.search'),
      "hint": _vm.$t('components.partner.PartnerReportInitializeDialogFromDa.customer.searchHint')
    },
    on: {
      "click:append-outer": _vm.findCustomer,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.findCustomer.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchCustomer,
      callback: function callback($$v) {
        _vm.searchCustomer = $$v;
      },
      expression: "searchCustomer"
    }
  })], 1), _vm.loading ? _c('v-card-text', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1) : _vm.customers.length > 0 ? _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.customers,
      "items-per-page": 5
    },
    on: {
      "click:row": _vm.click
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.customers
    }
  }), _vm.customerUrl ? _c('v-text-field', {
    staticClass: "mr-4 ml-4",
    attrs: {
      "value": _vm.customerUrl,
      "auto-grow": "",
      "outlined": "",
      "readonly": "",
      "rows": "1",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.result')
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c('v-tab-item', {
    key: "1"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "loading": _vm.loading,
      "outlined": "",
      "append-outer-icon": "mdi-magnify",
      "label": _vm.$t('components.partner.PartnerReportInitializeDialogFromDa.serviceEventId.search'),
      "hint": _vm.$t('components.partner.PartnerReportInitializeDialogFromDa.serviceEventId.searchHint')
    },
    on: {
      "click:append-outer": _vm.findServiceEvent,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.findServiceEvent.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchLicensePlate,
      callback: function callback($$v) {
        _vm.searchLicensePlate = $$v;
      },
      expression: "searchLicensePlate"
    }
  })], 1), _vm.loading ? _c('v-card-text', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1) : _vm.serviceEvents ? _c('v-card-text', [_c('debug', {
    attrs: {
      "debug": _vm.serviceEvents
    }
  }), _c('v-data-table', {
    attrs: {
      "headers": _vm.serviceEventHeaders,
      "items": _vm.serviceEvents
    },
    on: {
      "click:row": _vm.click2
    }
  }), _vm.isKsrSetup && _vm.serviceEvents.length > 0 ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "KSR Auftragsnummer",
      "hint": "Optional die KSR Auftragsnummer hinzufügen um Aufträge mit KSR zu verknüpfen",
      "persistent-hint": ""
    },
    on: {
      "blur": _vm.appendKsr
    },
    model: {
      value: _vm.ksrNr,
      callback: function callback($$v) {
        _vm.ksrNr = $$v;
      },
      expression: "ksrNr"
    }
  }) : _vm._e(), _vm.serviceEventUrl ? _c('v-text-field', {
    attrs: {
      "value": _vm.serviceEventUrl,
      "auto-grow": "",
      "outlined": "",
      "readonly": "",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.result')
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setNextStep(_vm.PagesEnum.CHOICE);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.back")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "text": "",
      "outlined": "",
      "color": "info",
      "disabled": !_vm.customerUrl && !_vm.serviceEventUrl
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.sendMail.next")) + " ")])], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isConfirmDialogActive
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isConfirmDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isConfirmDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isConfirmDialogActive = false;
      },
      "rightClick": _vm.reset
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }