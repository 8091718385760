var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading || _vm.isMounting ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article@1"
    }
  }) : _vm.value ? _c('v-card', {
    attrs: {
      "flat": "",
      "loading": _vm.loading || _vm.isMounting
    }
  }, [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions,
      "chips": _vm.chips,
      "title": _vm.value.content.subject
    },
    on: {
      "actionClicked": function actionClicked(action) {
        return action.exec();
      }
    }
  }), _vm.canCreateMessage ? _c('template-dialog', {
    ref: "templateDialog",
    attrs: {
      "hideButton": true,
      "partnerId": _vm.value.partnerId,
      "to": _vm.value.from,
      "from": [_vm.partner]
    }
  }) : _vm._e(), _c('v-divider'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "9"
    }
  }, [_c('div', {
    staticClass: "pb-3 pt-2 ms-4"
  }, [_c('v-avatar', {
    staticClass: "white--text",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.getAvatar(_vm.fromMail)) + " ")]), _c('div', {
    staticClass: "d-inline-flex flex-column justify-center ms-3",
    staticStyle: {
      "vertical-align": "middle"
    }
  }, [_c('span', {
    staticClass: "text--primary text-truncate font-weight-semibold mb-n1"
  }, [_vm._v(" " + _vm._s(_vm.fromMail) + " ")]), _c('small', {
    staticClass: "text--disabled"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.PartnerMessageDetail.to", {
    mail: _vm.value.to
  })))]), _c('small', {
    staticClass: "text--disabled"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.PartnerMessageDetail.from", {
    mail: _vm.value.from
  })))])]), _c('v-spacer')], 1)]), _c('v-col', {
    attrs: {
      "align": "right",
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-card-text', [_vm._v(_vm._s(_vm.date))])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('template-editor', {
    staticClass: "mt-2 mx-n2 pb-2",
    attrs: {
      "value": _vm.value.content.body,
      "readOnly": true,
      "outlined": false
    }
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isArchiveDialogOpen,
      "absolute": true,
      "title": _vm.$t('common.verbs.archive') + '?',
      "rightLoading": _vm.isArchiveLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isArchiveDialogOpen = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isArchiveDialogOpen = $event;
      },
      "leftClick": _vm.abortArchive,
      "rightClick": _vm.archive
    }
  })], 1) : _c('latest-entries-card-empty');
}
var staticRenderFns = []

export { render, staticRenderFns }