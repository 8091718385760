var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-divider'), _c('PartnerFooter', {
    staticClass: "d-md-flex",
    attrs: {
      "partnerProp": _vm.getInformations,
      "color": _vm.getFooterColor,
      "fontColor": _vm.getFontColor,
      "showLogo": false
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }