var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "label": _vm.$t('components.template.editor.subject')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.addPlaceholderInSubject ? _c('template-editor-add-placeholder', {
          staticClass: "mt-n2",
          attrs: {
            "partnerId": _vm.partnerId
          },
          on: {
            "addPlaceholder": _vm.addPlaceholderToSubject
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.subjectLocal,
      callback: function callback($$v) {
        _vm.subjectLocal = $$v;
      },
      expression: "subjectLocal"
    }
  }), _c('v-card-actions', {
    staticClass: "mt-n10"
  }, [_c('v-spacer'), _c('small', {
    style: _vm.subjectCounterStyle
  }, [_vm._v(" " + _vm._s(_vm.subjectLength) + "/60 ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }