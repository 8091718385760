var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading,
      "outlined": "",
      "x-small": "",
      "dense": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.isDeleteDialogActive = true;
      }
    }
  }, [_c('span', {
    staticClass: "ma-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerReportDetailChangeProgress.delete")) + " ")])]), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.title ? _vm.title : _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "isDialogActive": _vm.isDeleteDialogActive,
      "rightLoading": _vm.isLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": function rightClick($event) {
        return _vm.$emit('delete');
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }