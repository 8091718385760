var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-delete-outline")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v("Wollen Sie die Berechtigung wirklich löschen?")])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_vm.permissionToDelete.type ? _c('p', [_vm._v("Ressourcentyp: " + _vm._s(_vm.permissionToDelete.type))]) : _vm._e(), _vm.permissionToDelete.id ? _c('p', [_vm._v("RessourcenId: " + _vm._s(_vm.permissionToDelete.id))]) : _vm._e(), _vm.permissionToDelete.action ? _c('p', [_vm._v("Aktionen: " + _vm._s(_vm.permissionToDelete.action))]) : _vm._e()]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Abbrechen ")]), _c('v-btn', {
    attrs: {
      "color": "error",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.removePermission(_vm.permissionToDelete);
      }
    }
  }, [_vm._v(" Löschen ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }