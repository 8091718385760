var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-phone")])], 1), _c('v-list-item-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n4",
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.profile.EditProfileDialog.phone'),
      "rules": _vm.phoneNumberRules,
      "data-test-phone": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.update.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.update, "phone", $$v);
      },
      expression: "update.phone"
    }
  })], 1)], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-email")])], 1), _c('v-list-item-content', [_c('v-text-field', {
    staticClass: "pb-4 mt-n2",
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.profile.EditProfileDialog.email'),
      "rules": _vm.emailRules,
      "data-test-email": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.update.email,
      callback: function callback($$v) {
        _vm.$set(_vm.update, "email", $$v);
      },
      expression: "update.email"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-row', [!_vm.disabled ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "ml-16 mb-6 mt-n4",
    attrs: {
      "disabled": !_vm.valid,
      "loading": _vm.isLoading,
      "outlined": "",
      "large": "",
      "color": "primary",
      "data-test-profile-submit": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")]), _vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.save")) + " ")], 1)], 1) : _vm._e(), !_vm.disabled ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "ml-16 mb-6 mt-n4",
    attrs: {
      "loading": _vm.isLoading,
      "outlined": "",
      "large": "",
      "color": "warning",
      "data-test-profile-abort": ""
    },
    on: {
      "click": _vm.abort
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.abort")) + " ")], 1)], 1) : _vm._e(), _vm.disabled ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "ml-16 mb-6 mt-n4",
    attrs: {
      "loading": _vm.isLoading,
      "outlined": "",
      "large": "",
      "color": "primary",
      "data-test-profile-edit": ""
    },
    on: {
      "click": function click($event) {
        _vm.disabled = !_vm.disabled;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.edit")) + " ")], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }