var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-img', {
    staticClass: "mt-8",
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "250px",
      "src": require("@/assets/partner_contact_success.svg")
    }
  }), _c('v-card-actions', [_c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t("components.partner.UserAdministrationResendInvitationDialog.success")) + " "), _c('v-spacer')], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.setNextStep(_vm.PagesEnum.CHOICE);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.next")) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }