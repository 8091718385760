var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": _vm.$t('components.partner.PartnerBillingInformationDataCard.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.hasPermissions ? _c('partner-billing-information-data-dialog', {
          attrs: {
            "billingInformationDocument": _vm.billingInformation
          },
          on: {
            "submit": _vm.saveBillingDataForPartner
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('v-card-text', [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1) : !_vm.hasPermissions ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerBillingInformationDataCard.noPem")) + " ")]) : _vm.isBillingInformation ? _c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [[_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-vpn")])], 1), _c('v-list-item-content', [_vm.billingInformation.isCompany ? _c('v-list-item-title', [_vm._v(_vm._s(_vm.billingInformation.company))]) : _vm._e(), _vm.billingInformation.lastName ? _c('v-list-item-subtitle', [_vm.billingInformation.isCompany ? _c('span', [_vm._v("z. Hd.")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.billingInformation.firstName) + " " + _vm._s(_vm.billingInformation.lastName))]) : _vm._e(), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.billingInformation.address.street) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.billingInformation.address.zip) + " " + _vm._s(_vm.billingInformation.address.city) + " ")])], 1)], 1), _c('v-divider', {
    attrs: {
      "inset": ""
    }
  }), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-identifier")])], 1), _vm.billingInformation.isTaxDeductible ? _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.billingInformation.taxnumber))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataCard.taxNumber")))])], 1) : _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataCard.taxInfo")))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataCard.taxDescription")))])], 1)], 1), _c('v-divider', {
    attrs: {
      "inset": ""
    }
  })], _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-phone")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$helpers.showValueWithDefault(_vm.billingInformation.contact.phone, "Keine Telefonnummer angegeben")))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.$t("components.profile.ProfileCard.telephone")))])], 1)], 1), _c('v-divider', {
    attrs: {
      "inset": ""
    }
  }), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-email")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$helpers.showValueWithDefault(_vm.billingInformation.contact.email, "Keine E-Mail angegeben")))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.$t("components.profile.ProfileCard.email")))])], 1)], 1)], 2) : _c('v-card-text', [_c('latest-entries-card-empty')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }