var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_c('template-detail', {
    attrs: {
      "partnerId": _vm.partnerId,
      "loading": _vm.loading
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }