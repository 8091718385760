var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-docphant', [_c('v-container', {
    staticClass: "errorContainer"
  }, [_c('docphant-error-card', {
    attrs: {
      "errorMessage": _vm.errorMessage
    }
  })], 1), _c('docphant-footer', {
    attrs: {
      "partner": _vm.partner
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }