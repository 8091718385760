var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-keyboard")])];
      }
    }])
  }, [_c('span', [_c('v-simple-table', {
    staticStyle: {
      "background-color": "rgba(255,255,255,0)"
    }
  }, [_c('tbody', {
    staticStyle: {
      "width": "400px"
    }
  }, _vm._l(_vm.options, function (option, index) {
    return _c('tr', {
      key: 'option' + index,
      staticClass: "mt-n2 mb-n2",
      staticStyle: {
        "color": "white"
      }
    }, [_c('td', {
      staticStyle: {
        "border": "0"
      }
    }, [_c('v-chip', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_vm._v(" ALT ")]), option.shift ? _c('v-icon', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_vm._v("mdi-plus")]) : _vm._e(), option.shift ? _c('v-chip', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_vm._v(" SHIFT ")]) : _vm._e(), option.enter ? _c('v-icon', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_vm._v("mdi-plus")]) : _vm._e(), option.enter ? _c('v-chip', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_vm._v(" ENTER ")]) : _vm._e(), option.key ? _c('v-icon', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_vm._v("mdi-plus")]) : _vm._e(), option.key ? _c('v-chip', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'white',
        "x-small": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(_vm._s(option.key))])], 1) : _vm._e()], 1), _c('td', {
      staticStyle: {
        "border": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("components.template.editor.".concat(option.text))) + " ")])]);
  }), 0)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }