var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.loading ? _c('Loader') : _c('div', {
    staticClass: "machinePage"
  }, [_c('div', {
    attrs: {
      "id": "header"
    },
    on: {
      "click": function click($event) {
        return _vm.goHome();
      }
    }
  }, [_vm.machineInfo.manufacturer.logoUrl !== 'N/A' ? _c('TopToolbarLogo', {
    attrs: {
      "elevation": 0,
      "src": _vm.machineInfo.manufacturer.logoUrl
    }
  }) : _vm._e()], 1), _c('v-container', {
    staticClass: "pa-0 content",
    attrs: {
      "id": "cardContainer"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "4",
      "md": "4"
    }
  }, [_c('v-container', {
    staticClass: "machineContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('ManufacturerCard', {
    attrs: {
      "manufacturer": _vm.machineInfo.manufacturer
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "8",
      "lg": "8",
      "md": "8"
    }
  }, [_c('v-container', {
    staticClass: "machineContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticClass: "mx-2",
    attrs: {
      "outlined": "",
      "elevation": "0"
    }
  }, [_c('MachineCard', {
    attrs: {
      "machine": _vm.machineInfo
    }
  }), _c('v-divider'), _vm.machineInfo.machineAttachment.length !== 0 ? _c('AttachmentsCard', {
    attrs: {
      "machine": _vm.machineInfo
    }
  }) : _c('v-container', {
    staticClass: "machineContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Kein Anhänge vorhanden ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _vm.machineInfo ? _c('DocphantFooter', {
    attrs: {
      "manufacturer": _vm.machineInfo.manufacturer
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }