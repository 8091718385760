var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "title"
  }, [_vm._v("Wollen Sie diese Maschine Wirklich löschen?")])]), _c('v-card-text', [_vm._v(" Dieser Vorgang kann nicht Rückgängig gemacht werden. Bitte stellen Sie sicher, dass keine Dokumente mehr zugeordnet sind. ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.cancelMachineDeleteAndCloseDialog
    }
  }, [_vm._v(" Abbrechen ")]), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.confirmMachineDelete
    }
  }, [_vm._v(" Löschen ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }