var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          on: {
            "click": _vm.initializeManufacturerUpdateDto
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Bearbeiten ")])];
      }
    }]),
    model: {
      value: _vm.manufacturerUpdateDialog,
      callback: function callback($$v) {
        _vm.manufacturerUpdateDialog = $$v;
      },
      expression: "manufacturerUpdateDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "title"
  }, [_vm._v("Hersteller bearbeiten")])]), _c('v-card-text', [_c('v-form', [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Herstellername *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.name,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto, "name", $$v);
      },
      expression: "manufacturerDto.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Url zum Herstellerlogo *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.logoUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto, "logoUrl", $$v);
      },
      expression: "manufacturerDto.logoUrl"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Straße *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto.address, "street", $$v);
      },
      expression: "manufacturerDto.address.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Postleizahl *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto.address, "zip", $$v);
      },
      expression: "manufacturerDto.address.zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Stadt *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto.address, "city", $$v);
      },
      expression: "manufacturerDto.address.city"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Staat *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto.address, "state", $$v);
      },
      expression: "manufacturerDto.address.state"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "E-Mail *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto.contact, "email", $$v);
      },
      expression: "manufacturerDto.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Telefon *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerDto.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturerDto.contact, "phone", $$v);
      },
      expression: "manufacturerDto.contact.phone"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeUpdate
    }
  }, [_vm._v(" Abbrechen ")]), _c('v-btn', {
    on: {
      "click": _vm.confirmUpdate
    }
  }, [_vm._v(" Speichern ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }