var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "1200px"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('partner-report-detail-info-message-card', {
    attrs: {
      "message": _vm.report.message
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "lg": "4"
    }
  }, [_c('customer-contact-card', {
    attrs: {
      "customerName": _vm.report.customerName,
      "customerContact": _vm.report.customerContact,
      "address": _vm.report.customerAddress
    }
  }), _vm.report.datePreference ? _c('partner-report-detail-info-date-card', {
    attrs: {
      "date": _vm.report.datePreference
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "lg": "4"
    }
  }, [_c('partner-registration-card', {
    attrs: {
      "loading": _vm.registrationUpdateLoading,
      "registration": _vm.report.registrationResults,
      "numberplate": _vm.report.numberplate,
      "registrationImageLink": _vm.getRegistrationImageLink
    },
    on: {
      "update": _vm.updateRegistration
    }
  })], 1), _c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "height": "375px",
      "min-width": "230px",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetailInfoCard.damageLocator.title")) + " ")])]), _c('v-divider'), _c('v-row', {
    staticClass: "justify-center fluid"
  }, [_c('v-card', {
    staticClass: "mt-12",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "flat": "",
      "min-width": _vm.getDamageLocatorWidth,
      "max-height": "450px"
    }
  }, [_c('div', {
    staticClass: "center"
  }, [_vm.renderComponent ? _c('damage-locator-combined', {
    ref: "DamageLocator",
    attrs: {
      "isEdit": false,
      "reportType": _vm.report.reportType,
      "clickedColor": "#6fb38a",
      "damage-locations": _vm.damageLocation
    },
    on: {
      "damageLocation": _vm.forceRerender
    }
  }) : _vm._e()], 1)])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm.hasImages ? _c('partner-report-detail-image-card', {
    attrs: {
      "images": _vm.getImages
    }
  }) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.witness.length ? _c('partner-report-detail-info-witness-card', {
    attrs: {
      "witness": _vm.witness
    }
  }) : _vm._e()], 1)], 1), _vm.hasBody ? _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetailInfoBody.title")))])]), _c('v-divider'), _c('v-simple-table', [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetailInfoBody.key")) + " ")]), _c('th', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetailInfoBody.value")) + " ")])])]), _c('tbody', _vm._l(_vm.report.body || [], function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(Object.keys(item)[0]))]), _c('td', [_vm._v(_vm._s(Object.values(item)[0]))])]);
  }), 0)])], 1)], 1)], 1) : _vm._e(), _vm.metas.length > 0 ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.metas, function (meta) {
    return _c('v-col', {
      key: meta._id,
      attrs: {
        "cols": "6",
        "md": _vm.metaRowAmount
      }
    }, [_c('partner-report-detail-meta-card', {
      attrs: {
        "meta": meta
      }
    })], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }