var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._t("title", function () {
    return [_c('v-card-title', {
      staticClass: "title pt-4"
    }, [_c('h3', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-spacer'), _vm._t("options"), _c('context-menu', [_vm.isCsvExport ? _c('v-btn', {
      staticClass: "mt-1",
      attrs: {
        "text": "",
        "outlined": ""
      },
      on: {
        "click": _vm.download
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.download")) + " ")]) : _vm._e(), _vm.isLoadAll !== undefined ? _c('v-switch', {
      staticClass: "ml-2 mr-2",
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportList.loadAll')
      },
      model: {
        value: _vm.isLoadAll,
        callback: function callback($$v) {
          _vm.isLoadAll = $$v;
        },
        expression: "isLoadAll"
      }
    }) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": _vm.refresh
      }
    }, [_c('v-icon', [_vm._v(" mdi-refresh ")])], 1), _c('v-btn', {
      attrs: {
        "text": "",
        "outlined": ""
      },
      on: {
        "click": _vm.refresh
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportList.load")) + " ")])], 1)], 2)];
  }), _vm.isLoadAll && _vm.progress < 100 ? _c('v-progress-linear', {
    attrs: {
      "value": _vm.progress
    }
  }) : _vm._e(), _c('v-card', {
    staticClass: "flex-container",
    attrs: {
      "flat": ""
    }
  }, [_c('filter-card-pagination', {
    attrs: {
      "filterList": _vm.paginationFilterList,
      "filter": _vm.paginationFilter,
      "predefinedFilter": _vm.predefinedFilter,
      "search": _vm.search,
      "isSearchLoading": _vm.loading
    },
    on: {
      "update:filter": function updateFilter($event) {
        _vm.paginationFilter = $event;
      },
      "update:search": function updateSearch($event) {
        _vm.search = $event;
      },
      "confirmFilter": _vm.refresh,
      "confirmSearch": _vm.refresh
    }
  }), _c('div', {
    staticClass: "scrollable"
  }, [_vm.paginationList.length !== 0 ? _c('v-virtual-scroll', {
    attrs: {
      "itemHeight": 77,
      "items": _vm.paginationList
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_vm._t("listItem", null, {
          "listItem": item
        }), _vm.showLoadMore(index) ? _c('v-btn', {
          attrs: {
            "elevation": "0",
            "block": "",
            "text": "",
            "loading": _vm.isLoadingMore
          },
          on: {
            "click": _vm.loadMore
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportList.loadMore")) + " ")]) : _vm._e()];
      }
    }], null, true)
  }) : _vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "table-heading, divider, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _c('v-card', {
    staticClass: "justify-center align-center",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "justify-center mt-12",
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', _vm._g({
    attrs: {
      "src": _vm.emptyDataSrc,
      "height": "100",
      "width": "200",
      "contain": "",
      "justify": "center"
    }
  }, _vm.on))], 1), _c('v-card-subtitle', {
    staticClass: "justify-center  text--secondary",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportList.noData")) + " ")]), _c('v-card-actions', {
    staticClass: "justify-center",
    attrs: {
      "align": "center"
    }
  }, [_vm._t("emptyActions")], 2)], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }