var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [!_vm.isDetailLoading ? _c('partner-message-detail', {
    attrs: {
      "value": _vm.message
    }
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }