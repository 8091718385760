var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-tabs', {
    attrs: {
      "background-color": "transparent",
      "color": "basil",
      "grow": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("General")]), _c('v-tab', [_vm._v("Landing")]), _c('v-tab', [_vm._v("Report")]), _c('v-tab', [_vm._v("Licenses")]), _c('v-tab', [_vm._v("Inbox")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    key: "0"
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-vpn")])], 1), _c('v-list-item-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, _vm._l(_vm.weekDays, function (weekday) {
    return _c('div', {
      key: weekday
    }, [_c('v-text-field', {
      attrs: {
        "type": "text",
        "label": _vm.$t("partner.PartnerDetailSettingsOpeningHoursCard.".concat(weekday)),
        "outlined": "",
        "disabled": _vm.disabled
      },
      model: {
        value: _vm.update.openingHours[weekday],
        callback: function callback($$v) {
          _vm.$set(_vm.update.openingHours, weekday, $$v);
        },
        expression: "update.openingHours[weekday]"
      }
    })], 1);
  }), 0)], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-globe")])], 1), _c('v-list-item-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "type": "text",
      "label": "Email *",
      "hint": "Email Anzeige im Footer",
      "disabled": _vm.disabled,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.update.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.update.contact, "email", $$v);
      },
      expression: "update.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.phoneRules,
      "type": "text",
      "label": "Telefon",
      "hint": "Telefon Anzeige im Footer",
      "disabled": _vm.disabled,
      "outlined": ""
    },
    model: {
      value: _vm.update.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.update.contact, "phone", $$v);
      },
      expression: "update.contact.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Website Anzeige *",
      "rules": _vm.requiredRule,
      "hint": "Anzeigename für die Website, die im Footer sichtbar ist.",
      "disabled": _vm.disabled,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.update.websites[0].text,
      callback: function callback($$v) {
        _vm.$set(_vm.update.websites[0], "text", $$v);
      },
      expression: "update.websites[0].text"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Websitelink *",
      "hint": "Link auf den referenziert wird",
      "disabled": _vm.disabled,
      "outlined": "",
      "required": "",
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.update.websites[0].link,
      callback: function callback($$v) {
        _vm.$set(_vm.update.websites[0], "link", $$v);
      },
      expression: "update.websites[0].link"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "AGB Anzeige",
      "hint": "Anzeigename für die AGB, die sichtbar ist.",
      "disabled": _vm.disabled,
      "outlined": ""
    },
    model: {
      value: _vm.update.agbUrl.text,
      callback: function callback($$v) {
        _vm.$set(_vm.update.agbUrl, "text", $$v);
      },
      expression: "update.agbUrl.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "AGB Link",
      "hint": "Link zur AGB auf die referenziert wird",
      "disabled": _vm.disabled,
      "outlined": ""
    },
    model: {
      value: _vm.update.agbUrl.link,
      callback: function callback($$v) {
        _vm.$set(_vm.update.agbUrl, "link", $$v);
      },
      expression: "update.agbUrl.link"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    key: "1"
  }, [_c('PartnerCreateOrUpdateLandingpageSettigns', {
    attrs: {
      "settings": _vm.update,
      "disabled": _vm.disabled
    }
  })], 1), _c('v-tab-item', {
    key: "2"
  }, [_c('PartnerCreateOrUpdateReportSettings', {
    attrs: {
      "reportSettings": _vm.update.reportSettings,
      "disabled": _vm.disabled
    }
  })], 1), _c('v-tab-item', {
    key: "3"
  }, [_c('PartnerCreateOrUpdateLicense', {
    attrs: {
      "settings": _vm.update,
      "disabled": _vm.disabled
    }
  })], 1), _c('v-tab-item', {
    key: "4"
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v(" Inbox ")]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "small": "",
      "dense": "",
      "outlined": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        _vm.isAddUpdateInboxesDialogActive = true;
      }
    }
  }, [_vm._v(" Inbox anlegen ")])], 1), _c('v-card-text', _vm._l(_vm.update.inboxes, function (inbox, index) {
    return _c('v-list-item', {
      key: 'inboxes' + index,
      attrs: {
        "outlined": ""
      }
    }, [_c('v-list-item-action', [_c('v-checkbox', {
      attrs: {
        "disabled": _vm.disabled
      },
      model: {
        value: inbox.isActive,
        callback: function callback($$v) {
          _vm.$set(inbox, "isActive", $$v);
        },
        expression: "inbox.isActive"
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(inbox.name) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(inbox.identifier) + " ")])], 1), _c('v-list-item-action', [!_vm.disabled ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.update.inboxes.splice(index, 1);
        }
      }
    }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1) : _vm._e()], 1)], 1);
  }), 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isAddUpdateInboxesDialogActive,
      "title": "Inbox anlegen",
      "rightDisabled": !_vm.isUpdateInboxesValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isAddUpdateInboxesDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isAddUpdateInboxesDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isAddUpdateInboxesDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isAddUpdateInboxesDialogActive = false;
      },
      "rightClick": _vm.addNewInbox
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isUpdateInboxesValid,
      callback: function callback($$v) {
        _vm.isUpdateInboxesValid = $$v;
      },
      expression: "isUpdateInboxesValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": "Name",
      "disabled": _vm.disabled,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newInbox.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newInbox, "name", $$v);
      },
      expression: "newInbox.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.mailRule,
      "label": "Inbox Mail",
      "disabled": _vm.disabled,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newInbox.identifier,
      callback: function callback($$v) {
        _vm.$set(_vm.newInbox, "identifier", $$v);
      },
      expression: "newInbox.identifier"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Aktiv",
      "disabled": _vm.disabled,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newInbox.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.newInbox, "isActive", $$v);
      },
      expression: "newInbox.isActive"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-row', [!_vm.disabled ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "ml-16 mb-6",
    attrs: {
      "disabled": !_vm.valid,
      "loading": _vm.isLoading,
      "outlined": "",
      "large": "",
      "color": "primary",
      "data-test-profile-submit": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")]), _vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.save")) + " ")], 1)], 1) : _vm._e(), !_vm.disabled ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "ml-16 mb-6",
    attrs: {
      "loading": _vm.isLoading,
      "outlined": "",
      "large": "",
      "color": "warning",
      "data-test-profile-abort": ""
    },
    on: {
      "click": _vm.abort
    }
  }, [_c('v-icon', [_vm._v("mdi-close")]), _vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.abort")) + " ")], 1)], 1) : _vm._e(), _vm.disabled ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "ml-16 mb-6 ",
    attrs: {
      "loading": _vm.isLoading,
      "outlined": "",
      "large": "",
      "color": "primary",
      "data-test-profile-edit": ""
    },
    on: {
      "click": function click($event) {
        _vm.disabled = !_vm.disabled;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")]), _vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.edit")) + " ")], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }