var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "fullscreen": true,
      "isDialogActive": _vm.dialog,
      "title": _vm.$t('timeLine.ReportActivityBoxShareComponent.dialogTitle'),
      "hideLeft": !_vm.isPrevious,
      "hideRight": !_vm.isNext,
      "leftText": _vm.previousLabel,
      "rightText": _vm.nextLabel,
      "supressKeyboardActions": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": _vm.onPrevious,
      "rightClick": _vm.onNext
    }
  }, [_c('debug', [_vm._v(" step" + _vm._s(_vm.step)), _c('br'), _vm._v(" isPrevious" + _vm._s(_vm.isPrevious)), _c('br'), _vm._v(" previousLabel" + _vm._s(_vm.previousLabel)), _c('br'), _vm._v(" onPrevious" + _vm._s(_vm.onPrevious)), _c('br'), _vm._v(" isNext" + _vm._s(_vm.isNext)), _c('br'), _vm._v(" nextLabel" + _vm._s(_vm.nextLabel)), _c('br'), _vm._v(" onNext" + _vm._s(_vm.onNext)), _c('br')]), _vm.dialog ? _c('report-activity-box-share-component', {
    ref: "reportActivityShareBoxComponent",
    attrs: {
      "actionButton": false,
      "report": _vm.report,
      "hideButton": true
    },
    on: {
      "setStep": _vm.setStep,
      "setLoading": _vm.setLoading
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }