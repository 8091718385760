var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {},
      "controlElements": _vm.controlElements
    },
    scopedSlots: _vm._u([{
      key: "item.timestamps.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamps.created)) + " ")];
      }
    }, {
      key: "item.roles",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.roles, function (role, i) {
          return _c('v-chip', {
            key: i,
            attrs: {
              "label": "",
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s(role) + " ")]);
        });
      }
    }, {
      key: "item.permission",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.permission.length) + " ")];
      }
    }, {
      key: "item._id",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'UserAdministrationDetail',
              params: {
                userId: item._id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item._id) + " ")])];
      }
    }])
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('components.partner.UserTable.confirmDeleteTitle'),
      "subtitle": _vm.$t('components.partner.UserTable.confirmDeleteSubtitle'),
      "leftLoading": _vm.isDeleteDialogLoading,
      "rightLoading": _vm.isDeleteDialogLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteDocument
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }