var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.loading,
            "icon": ""
          },
          on: {
            "click": _vm.startCheckout
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogActive,
      callback: function callback($$v) {
        _vm.isDialogActive = $$v;
      },
      expression: "isDialogActive"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v(_vm._s("QuickCheckout"))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('partner-selection-form', {
    attrs: {
      "loading": _vm.loading,
      "partnerId": _vm.partnerId
    },
    on: {
      "save": _vm.savePartner,
      "change": _vm.changePartner
    }
  })], 1), _c('v-card-text', [_vm.years.length < 1 ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line"
    }
  }) : _c('thg-dialog-year-selection', {
    attrs: {
      "possibleYears": _vm.years,
      "numberplate": _vm.numberplate
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.years
    }
  })], 1), _c('v-card-text', [_c('debug', [_vm._v(_vm._s(_vm.thg))]), _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-checkbox', {
    ref: "reviewed",
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('report.thgClosing.confirmation'),
      "required": "",
      "data-test-review-checkbox": ""
    },
    model: {
      value: _vm.isConfirmed,
      callback: function callback($$v) {
        _vm.isConfirmed = $$v;
      },
      expression: "isConfirmed"
    }
  }), _c('v-checkbox', {
    ref: "privacy",
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('report.thgClosing.confirmationPrivacy'),
      "required": "",
      "data-test-privacy-checkbox": ""
    },
    model: {
      value: _vm.isTermsAccepted,
      callback: function callback($$v) {
        _vm.isTermsAccepted = $$v;
      },
      expression: "isTermsAccepted"
    }
  }), _c('v-text-field', {
    attrs: {
      "disabled": "",
      "hint": "Selected Yearss",
      "label": "Years to checkout",
      "value": _vm.yearsToCheckout,
      "persistent-hint": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "disabled": "",
      "hint": "Beantragung im Kontext des Partners",
      "label": "Partner",
      "value": _vm.partnerId,
      "persistent-hint": ""
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "success",
      "disabled": !_vm.valid,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.quickCheckout
    }
  }, [_vm._v(" " + _vm._s("Save") + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }