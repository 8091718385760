var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    class: {
      'ma-0': _vm.$vuetify.breakpoint.smAndDown,
      'ma-2': _vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "loading": _vm.loading,
      "outlined": "",
      "flat": "",
      "title": _vm.$t(_vm.title)
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.showSwitch ? _c('v-switch', {
          staticClass: "mt-n1 mb-n1",
          attrs: {
            "hide-details": true,
            "label": _vm.$t(_vm.switchTitle)
          },
          model: {
            value: _vm.displayAverageTrend,
            callback: function callback($$v) {
              _vm.displayAverageTrend = $$v;
            },
            expression: "displayAverageTrend"
          }
        }) : _vm._e(), _c('analytics-data-table-time-series-dialog', {
          attrs: {
            "data": _vm.data
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.shopwKpiGroup ? _c('v-card-text', [_c('v-row', _vm._l(_vm.kpiGroup, function (kpi, i2) {
    return _c('analytics-kpi-column', {
      key: i2,
      attrs: {
        "icon": kpi.icon,
        "color": _vm.kpiColor(i2),
        "title": kpi.name,
        "data": kpi.value
      }
    });
  }), 1)], 1) : _vm._e(), _c('v-chart', {
    staticClass: "linechart",
    attrs: {
      "loading": _vm.loading,
      "option": _vm.chartOptions,
      "autoresize": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }