import { render, staticRenderFns } from "./TemplateDetailBodyCard.vue?vue&type=template&id=567b8953&scoped=true&"
import script from "./TemplateDetailBodyCard.vue?vue&type=script&lang=ts&"
export * from "./TemplateDetailBodyCard.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateDetailBodyCard.vue?vue&type=style&index=0&id=567b8953&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567b8953",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCardText,VCombobox,VTextField})
