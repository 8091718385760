var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.hideButton ? _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.MESSAGE,
      "field": _vm.partnerId
    }
  }, [_vm.isFeatureActive && !_vm.large ? _c('v-btn', {
    attrs: {
      "icon": "",
      "loading": _vm.externalLoading
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icon))])], 1) : _vm.isFeatureActive ? _c('v-btn', {
    staticClass: "ma-1",
    attrs: {
      "text": "",
      "block": "",
      "outlined": "",
      "large": "",
      "loading": _vm.externalLoading
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v(" Mail ")]) : _vm._e()], 1) : _vm._e(), _c('confirm-action-dialog', {
    key: _vm.partnerId || '' + _vm.from + _vm.to + JSON.stringify(_vm.context),
    attrs: {
      "fullscreen": _vm.fullscreen,
      "width": _vm.width,
      "isDialogActive": _vm.isDialogActive,
      "title": _vm.$t('components.template.dialog.preview.title'),
      "leftText": _vm.$t('components.template.dialog.preview.back'),
      "rightText": _vm.$t('components.template.dialog.preview.send'),
      "supressKeyboardActions": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.send
    }
  }, [_c('template-card', {
    ref: "templateCard",
    attrs: {
      "partnerId": _vm.partnerId,
      "from": _vm.from,
      "context": _vm.context,
      "loadContext": _vm.loadContext,
      "to": _vm.to
    },
    on: {
      "end": function end($event) {
        return _vm.$emit('end');
      },
      "sent": _vm.sent
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }