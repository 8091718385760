var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "6",
      "xl": "3",
      "lg": "4",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-avatar', {
    staticClass: "elevation-1",
    attrs: {
      "size": "44",
      "color": _vm.color,
      "rounded": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": "",
      "color": "white",
      "size": "30"
    }
  }, [_vm._v(_vm._s(_vm.icon))])], 1), _c('div', {
    staticClass: "ms-3"
  }, [_c('h2', {
    staticClass: "text-4xl font-weight-semibold"
  }, [_vm._v(" " + _vm._s(_vm.data) + " ")]), _c('p', {
    staticClass: "text-xs mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }