var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.status ? _c('v-chip', {
    attrs: {
      "color": _vm.colorMap.get(_vm.status),
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }