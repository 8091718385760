var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "formContainer"
  }, [_c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('h1', {
    staticClass: "title",
    attrs: {
      "id": "upperTitle"
    }
  }, [_vm._v("Ihr Produkt:")])])], 1), _c('v-card-text', [!_vm.hideManufacturerInput ? _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Hersteller ID *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturerId,
      callback: function callback($$v) {
        _vm.manufacturerId = $$v;
      },
      expression: "manufacturerId"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Produkt ID *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineId,
      callback: function callback($$v) {
        _vm.machineId = $$v;
      },
      expression: "machineId"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "buttonContainer"
  }, [_c('v-btn', {
    staticClass: "selectionSubmitButton",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "disabled": !_vm.valid,
      "id": "selectionSubmitButton",
      "rounded": "",
      "x-large": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Suchen ")])], 1)], 1), _c('br'), _c('v-card-title', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('h3', {
    staticClass: "title",
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      "id": "lowerTitle"
    }
  }, [_vm._v(" Hier finden Sie Ihre Hersteller- und Produkt ID ")])])], 1), _c('v-img', {
    attrs: {
      "lazy-src": "https://www.mmmint.ai/partner/digitalmanual/machine_and_name_chield.jpg",
      "src": "https://www.mmmint.ai/partner/digitalmanual/machine_and_name_chield.jpg",
      "alt": "Abbildung eines Produktes. Die Produkt ID und die Hersteller ID sind an der linken, oberen Ecke hervorgehoben."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }