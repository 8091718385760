var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "absolute"
  }, [_c('div', {
    attrs: {
      "id": "filterBackground"
    },
    on: {
      "click": function click($event) {
        return _vm.clickBackground($event);
      }
    }
  }, [_c('v-container', {
    staticClass: "filterContainer"
  }, [_c('v-card', {
    staticClass: "filterCard"
  }, [_c('v-card-title', [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" Filterauswahl ")]), _c('v-col', {
    attrs: {
      "cols": "2",
      "align": "center"
    }
  }, [_c('v-icon', {
    staticClass: "mt-2",
    on: {
      "click": _vm.filter
    }
  }, [_vm._v(" mdi-close-circle-outline ")])], 1)], 1), _c('v-card-text', [_c('v-form', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Editor",
      "type": "text",
      "outlined": ""
    },
    model: {
      value: _vm.filters.selectedEditor,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "selectedEditor", $$v);
      },
      expression: "filters.selectedEditor"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Startdatum",
      "type": "date",
      "outlined": ""
    },
    model: {
      value: _vm.filters.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "startDate", $$v);
      },
      expression: "filters.startDate"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Enddatum",
      "type": "date",
      "outlined": ""
    },
    model: {
      value: _vm.filters.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "endDate", $$v);
      },
      expression: "filters.endDate"
    }
  }), _c('v-combobox', {
    attrs: {
      "items": _vm.languages,
      "search-input": _vm.language,
      "return-object": "",
      "label": "Sprachen",
      "multiple": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              return _vm.removeFilterItem(item, 'selectedLanguages');
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item))])])];
      }
    }]),
    model: {
      value: _vm.filters.selectedLanguages,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "selectedLanguages", $$v);
      },
      expression: "filters.selectedLanguages"
    }
  }), _c('v-combobox', {
    attrs: {
      "item-text": _vm.type,
      "items": _vm.machineAttachmentTypes,
      "label": "Typen",
      "multiple": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              return _vm.removeFilterItem(item, 'selectedAttachementTypes');
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item))])])];
      }
    }]),
    model: {
      value: _vm.filters.selectedAttachementTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "selectedAttachementTypes", $$v);
      },
      expression: "filters.selectedAttachementTypes"
    }
  }), _c('v-combobox', {
    attrs: {
      "item-text": _vm.status,
      "items": _vm.machineStatus,
      "label": "Status",
      "multiple": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          attrs: {
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              return _vm.removeFilterItem(item, 'selectedStatus');
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item))])])];
      }
    }]),
    model: {
      value: _vm.filters.selectedStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "selectedStatus", $$v);
      },
      expression: "filters.selectedStatus"
    }
  }), _c('v-col', {
    staticClass: "submitButtonContainer",
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "submitButton",
    attrs: {
      "rounded": ""
    },
    on: {
      "click": _vm.filter
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-magnify ")]), _vm._v(" Filter ")], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }