var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.openDialog
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "pb-4"
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-6"
  }, [_c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.company")))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.billingInformationDto.isCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto, "isCompany", $$v);
      },
      expression: "billingInformationDto.isCompany"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.company'),
      "value": true,
      "data-test-form-iscompany": ""
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.private'),
      "value": false,
      "data-test-form-isprivate": ""
    }
  })], 1), _vm.billingInformationDto.isCompany ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.companyName')
    },
    model: {
      value: _vm.billingInformationDto.company,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto, "company", $$v);
      },
      expression: "billingInformationDto.company"
    }
  }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.name")))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.firstName')
    },
    model: {
      value: _vm.billingInformationDto.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto, "firstName", $$v);
      },
      expression: "billingInformationDto.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.lastName')
    },
    model: {
      value: _vm.billingInformationDto.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto, "lastName", $$v);
      },
      expression: "billingInformationDto.lastName"
    }
  })], 1)], 1), _c('p', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.address")))]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.street')
    },
    model: {
      value: _vm.billingInformationDto.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto.address, "street", $$v);
      },
      expression: "billingInformationDto.address.street"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.zip')
    },
    model: {
      value: _vm.billingInformationDto.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto.address, "zip", $$v);
      },
      expression: "billingInformationDto.address.zip"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.city')
    },
    model: {
      value: _vm.billingInformationDto.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto.address, "city", $$v);
      },
      expression: "billingInformationDto.address.city"
    }
  })], 1)], 1), _c('p', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.tax")))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.billingInformationDto.isTaxDeductible,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto, "isTaxDeductible", $$v);
      },
      expression: "billingInformationDto.isTaxDeductible"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.yes'),
      "value": true,
      "data-test-form-istax": ""
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.no'),
      "value": false,
      "data-test-form-isnottaxdeductible": ""
    }
  })], 1), _vm.billingInformationDto.isTaxDeductible ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.taxNumber')
    },
    model: {
      value: _vm.billingInformationDto.taxnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto, "taxnumber", $$v);
      },
      expression: "billingInformationDto.taxnumber"
    }
  }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.contact")))]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": "E-Mail"
    },
    model: {
      value: _vm.billingInformationDto.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto.contact, "email", $$v);
      },
      expression: "billingInformationDto.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.PartnerBillingInformationDataDialog.form.phone')
    },
    model: {
      value: _vm.billingInformationDto.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.billingInformationDto.contact, "phone", $$v);
      },
      expression: "billingInformationDto.contact.phone"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "ml-2 mt-n6"
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.editLoading,
      "color": "success",
      "outlined": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.confirm")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }