var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.openDialog
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('card', {
    attrs: {
      "margin": 0,
      "title": _vm.$t('components.partner.UserAdministrationDetailBanking.edit')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v("mdi-close")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-card-text', {
    staticClass: "pt-6"
  }, [_c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_vm.userId ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.UserAdministrationDetailBanking.userId'),
      "disabled": ""
    },
    model: {
      value: _vm.userId,
      callback: function callback($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  }) : _vm._e(), _vm.partnerId ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.UserAdministrationDetailBanking.partnerId'),
      "disabled": ""
    },
    model: {
      value: _vm.partnerId,
      callback: function callback($$v) {
        _vm.partnerId = $$v;
      },
      expression: "partnerId"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.UserAdministrationDetailBanking.userName')
    },
    model: {
      value: _vm.banking.name,
      callback: function callback($$v) {
        _vm.$set(_vm.banking, "name", $$v);
      },
      expression: "banking.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRules,
      "label": _vm.$t('components.partner.UserAdministrationDetailBanking.bankName')
    },
    model: {
      value: _vm.banking.bank,
      callback: function callback($$v) {
        _vm.$set(_vm.banking, "bank", $$v);
      },
      expression: "banking.bank"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.ibanRules,
      "label": _vm.$t('components.partner.UserAdministrationDetailBanking.iban')
    },
    model: {
      value: _vm.banking.iban,
      callback: function callback($$v) {
        _vm.$set(_vm.banking, "iban", $$v);
      },
      expression: "banking.iban"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "ml-2 mt-n6"
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.editLoading,
      "color": "success",
      "outlined": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.UserAdministrationDetailBanking.confirm")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }