var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    attrs: {
      "disabled": _vm.disabled,
      "outlined": "",
      "color": "error",
      "small": _vm.small,
      "loading": _vm.loading,
      "block": _vm.isBlock
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.DeleteDialog.delete")) + " ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialog,
      "max-width": "800",
      "title": _vm.$t(_vm.title),
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "rightLoading": _vm.loading,
      "rightDisabled": _vm.isDisabeld
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "rightClick": _vm.emitDelete,
      "leftClick": _vm.emitCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.text)) + " "), _c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "text",
      "label": _vm.$t('components.dialog.DeleteDialog.reasonTextField'),
      "rules": _vm.requiredRules,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }