var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pr-n10",
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v("Partner")]), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "to": {
              name: 'PartnerTable'
            },
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-table")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("partner.PartnerTable.title")))])]), _vm.$can(_vm.ActionEnum.CREATE, _vm.ResourceEnum.PARTNER) ? _c('partner-create-dialog') : _vm._e()], 1), _c('v-card', {
    staticClass: "flex-container",
    attrs: {
      "flat": ""
    }
  }, [_c('filter-card', {
    attrs: {
      "search": _vm.search,
      "filterList": _vm.filterList,
      "filterItems": _vm.partners,
      "filteredItems": _vm.filteredItems
    },
    on: {
      "update:search": function updateSearch($event) {
        _vm.search = $event;
      }
    }
  }), _c('div', {
    staticClass: "scrollable"
  }, [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_vm.filteredItems.length !== 0 ? _c('v-list-item-group', {
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.filteredItems, function (item) {
    return _c('v-list-item', {
      key: item._id,
      staticClass: "item",
      on: {
        "click": function click($event) {
          return _vm.setPartner(item);
        }
      }
    }, [item.settings ? _c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "contain": "",
        "src": item.settings.logoUrl
      }
    })], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s("".concat(item.companyName, " (").concat(item.companyUsername, ")")))]), item.address ? _c('v-list-item-subtitle', {
      staticClass: "text--primary"
    }, [_vm._v(" " + _vm._s(item.address.zip + " " + item.address.city) + " ")]) : _vm._e(), item.address ? _c('v-list-item-subtitle', [_vm._v(_vm._s(item.address.street))]) : _vm._e()], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.date(item)))]), _c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }