var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [!_vm.loadingAllTemplates ? _c('template-list', {
          attrs: {
            "partnerId": _vm.partnerId,
            "loading": _vm.loadingAllTemplates
          },
          on: {
            "setDetail": _vm.set,
            "refreshList": _vm.refreshList
          }
        }) : _c('v-skeleton-loader', {
          attrs: {
            "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('template-detail', {
          attrs: {
            "loading": _vm.loadingSelectedTemplate,
            "partnerId": _vm.partnerId,
            "displayToDetailView": true
          },
          on: {
            "refreshList": _vm.refreshList
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }