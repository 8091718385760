var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "quoteForm",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.change.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("components.thg.PartnerSelectionForm.partnerInfo")) + " ")])]), _c('v-row', {
    staticClass: "pb-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "required": "",
      "outlined": "",
      "data-test-partner": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.PartnerSelectionForm.partner'),
      "loading": _vm.loading,
      "items": _vm.partners,
      "cache-items": "",
      "hide-no-data": "",
      "item-text": "companyUsername",
      "item-value": "_id"
    },
    on: {
      "input": _vm.change,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.change.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.PartnerSelectionForm.tooltip.partner")) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.internalPartnerId,
      callback: function callback($$v) {
        _vm.internalPartnerId = $$v;
      },
      expression: "internalPartnerId"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }