import { render, staticRenderFns } from "./TemplateEditorSubject.vue?vue&type=template&id=540292ba&scoped=true&"
import script from "./TemplateEditorSubject.vue?vue&type=script&lang=ts&"
export * from "./TemplateEditorSubject.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateEditorSubject.vue?vue&type=style&index=0&id=540292ba&scoped=true&lang=css&"
import style1 from "./TemplateEditorSubject.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "540292ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCardActions,VSpacer,VTextField})
