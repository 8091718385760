var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "width": "800px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v("Partner ändern")])]), _c('v-divider'), _c('PartnerCreateOrUpdateGeneralInfos', {
    attrs: {
      "showUsername": false,
      "partnerDto": _vm.partnerDto
    }
  }), _c('PartnerCreateOrUpdateLandingpageSettigns', {
    attrs: {
      "settings": _vm.partnerDto.settings
    }
  }), _c('PartnerCreateOrUpdateReportSettings', {
    attrs: {
      "reportSettings": _vm.reportSettings
    }
  }), _c('PartnerCreateOrUpdateLicense', {
    attrs: {
      "settings": _vm.partnerDto.settings
    }
  }), _c('PartnerCreateOrUpdateSubmitCard', {
    attrs: {
      "isUpdatePartner": true,
      "partnerDto": _vm.partnerDto
    },
    on: {
      "closeDialog": function closeDialog($event) {
        return _vm.closeDialog();
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }