var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "titleField",
      "readonly": _vm.disabled,
      "label": _vm.$t('project.project.title'),
      "required": "",
      "rules": _vm.requiredRule,
      "outlined": ""
    },
    model: {
      value: _vm.project.title,
      callback: function callback($$v) {
        _vm.$set(_vm.project, "title", $$v);
      },
      expression: "project.title"
    }
  }), _c('v-textarea', {
    attrs: {
      "readonly": _vm.disabled,
      "label": _vm.$t('project.project.description'),
      "required": "",
      "rules": _vm.requiredRule,
      "outlined": ""
    },
    model: {
      value: _vm.project.description,
      callback: function callback($$v) {
        _vm.$set(_vm.project, "description", $$v);
      },
      expression: "project.description"
    }
  }), _c('v-card', {
    attrs: {
      "elevation": 0
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("views.CustomFieldList.customFields")) + " ")]), _c('custom-field-configuration-form', {
    attrs: {
      "valid": _vm.isValid,
      "disabled": _vm.disabled
    },
    on: {
      "update:valid": function updateValid($event) {
        _vm.isValid = $event;
      }
    },
    model: {
      value: _vm.project.configuration.customFieldConfig,
      callback: function callback($$v) {
        _vm.$set(_vm.project.configuration, "customFieldConfig", $$v);
      },
      expression: "project.configuration.customFieldConfig"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }