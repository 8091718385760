var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "absolute": true,
      "fullscreen": _vm.$vuetify.breakpoint.mobile,
      "width": "600px",
      "loading": _vm.isLoading,
      "hideTitle": true
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-container', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line"
    }
  }) : _c('operation-detail', {
    staticClass: "mb-10",
    attrs: {
      "hideBreadCrumbs": true,
      "operation": _vm.operation
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }