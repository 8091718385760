var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": "isLoading"
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("sign.SignRequestCreateDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-subtitle'), _c('v-card-text', [_c('sign-request-form', {
    ref: "signRequestForm",
    attrs: {
      "documents": _vm.documents,
      "isLoading": _vm.isLoading
    },
    on: {
      "save": _vm.save
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }