var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('TopToolbarLogo', {
    attrs: {
      "src": _vm.partner.settings.logoUrl,
      "elevation": 0
    }
  }), [_c('v-container', {
    staticClass: "loginContainer"
  }, [_c('LoginCard', {
    attrs: {
      "onLogin": _vm.onLogin
    }
  })], 1)], _c('DocphantFooter', {
    attrs: {
      "partner": _vm.partner
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }