import { render, staticRenderFns } from "./UserPermissionAddDialog.vue?vue&type=template&id=30d8fbed&scoped=true&"
import script from "./UserPermissionAddDialog.vue?vue&type=script&lang=ts&"
export * from "./UserPermissionAddDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d8fbed",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VIcon,VListItemContent,VListItemSubtitle,VListItemTitle,VSimpleTable})
