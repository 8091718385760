import { render, staticRenderFns } from "./PartnerReportDetail.vue?vue&type=template&id=7230312c&scoped=true&"
import script from "./PartnerReportDetail.vue?vue&type=script&lang=ts&"
export * from "./PartnerReportDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerReportDetail.vue?vue&type=style&index=0&id=7230312c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7230312c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCol,VContainer,VDivider,VFlex,VImg,VLayout,VProgressCircular,VRow,VSkeletonLoader})
