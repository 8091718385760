var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_vm.template && _vm.template.content ? _c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "readonly": "",
      "value": _vm.template.content.subject,
      "label": _vm.$t('components.template.detail.body.subject'),
      "width": "100%"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "preview"
  }, [_vm.template && _vm.template.content ? _c('template-editor', {
    key: _vm.template.content.body,
    attrs: {
      "readOnly": true,
      "value": _vm.template.content.body,
      "partnerId": _vm.partnerId,
      "label": _vm.$t('components.template.detail.body.body')
    }
  }) : _vm._e()], 1), _vm.template && _vm.template.content.tokens.length > 0 ? _c('v-combobox', {
    attrs: {
      "value": _vm.template.content.tokens,
      "label": _vm.$t('components.template.detail.body.tokens'),
      "chips": "",
      "readonly": "",
      "multiple": "",
      "outlined": "",
      "dense": "",
      "small-chips": ""
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }