var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(" Hinzufügen ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title"
  }, [_c('h3', [_vm._v("Neues Dokument anlegen.")])]), _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Dokumentenname *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.name,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "name", $$v);
      },
      expression: "machineAttachment.name"
    }
  }), _c('v-textarea', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Beschreibung *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.descripton,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "descripton", $$v);
      },
      expression: "machineAttachment.descripton"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "rules": _vm.rules,
      "items": _vm.machineAttachmentTypeEnum,
      "type": "text",
      "label": "Dokumententyp *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.attachmentType,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "attachmentType", $$v);
      },
      expression: "machineAttachment.attachmentType"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "rules": _vm.rules,
      "items": _vm.machineAttachmentStatusEnum,
      "type": "text",
      "label": "Status *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.status,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "status", $$v);
      },
      expression: "machineAttachment.status"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Interne Bezeichnung *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.attachmentCode,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "attachmentCode", $$v);
      },
      expression: "machineAttachment.attachmentCode"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Editor *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.editor,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "editor", $$v);
      },
      expression: "machineAttachment.editor"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "rules": _vm.rules,
      "items": _vm.languageEnum,
      "type": "text",
      "label": "Sprache *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.machineAttachment.language,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "language", $$v);
      },
      expression: "machineAttachment.language"
    }
  }), _c('v-file-input', {
    attrs: {
      "label": "File input",
      "accept": "application/pdf",
      "type": "file",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.machineAttachment.file,
      callback: function callback($$v) {
        _vm.$set(_vm.machineAttachment, "file", $$v);
      },
      expression: "machineAttachment.file"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeUpdate
    }
  }, [_vm._v(" Abbrechen ")]), _c('v-btn', {
    on: {
      "click": _vm.createMachine
    }
  }, [_vm._v(" Speichern ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }