var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "title": _vm.title,
      "subtitle": _vm.subtitle
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "pa-2"
  }, _vm._l(_vm.pages, function (page) {
    return _c('v-col', {
      key: page.subtitle,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "3"
      }
    }, [page.isEnabled ? _c('v-card', {
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-actions', {
      staticClass: "pt-8 pb-8"
    }, [_c('v-spacer'), _c('v-icon', {
      staticStyle: {
        "transform": "scale(2)"
      },
      attrs: {
        "x-large": "",
        "color": "info"
      }
    }, [_vm._v(" " + _vm._s(page.icon) + " ")]), _c('v-spacer')], 1), _c('h3', {
      staticClass: "text-center mb-n2"
    }, [_vm._v(" " + _vm._s(page.title) + " ")]), _c('v-card-subtitle', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(page.subtitle) + " ")]), _c('v-card-actions', [_c('v-btn', {
      attrs: {
        "block": "",
        "color": "info",
        "data-test-report-menu": page.nextPage
      },
      on: {
        "click": function click($event) {
          return _vm.setNextStep(page.nextPage);
        }
      }
    }, [_vm._v(" " + _vm._s(page.buttonText) + " ")])], 1)], 1) : _vm._e()], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }