var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "1000",
      "fullscreen": _vm.$vuetify.breakpoint.xs
    },
    scopedSlots: _vm._u([_vm.icon && !_vm.hideButton ? {
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attr = _ref.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "data-test-open-create-report-dialog": ""
          },
          on: {
            "click": _vm.initializeDialog
          }
        }, 'v-btn', attr, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    } : !_vm.hideButton ? {
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attr = _ref2.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "elevation": "0",
            "color": "info"
          },
          on: {
            "click": _vm.initializeDialog
          }
        }, 'v-btn', attr, false), on), [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.newReport")) + " ")])];
      }
    } : null], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-window', {
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.CHOICE
    }
  }, [_c('partner-report-initialize-dialog-selection', {
    attrs: {
      "step": _vm.step
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.QR
    }
  }, [_c('partner-report-initialize-dialog-qr', {
    attrs: {
      "step": _vm.step,
      "reportDto": _vm.reportDto
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.BATCH
    }
  }, [_c('partner-report-initialize-dialog-batch', {
    attrs: {
      "step": _vm.step,
      "url": _vm.url
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.MAIL
    }
  }, [_c('partner-report-initialize-dialog-mail', {
    attrs: {
      "step": _vm.step,
      "reportDto": _vm.reportDto
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "update": function update(v) {
        return _vm.url = v;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.EMPTY
    }
  }, [_c('partner-report-initialize-dialog-empty', {
    attrs: {
      "step": _vm.step,
      "reportDto": _vm.reportDto
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "update": function update(v) {
        return _vm.url = v;
      },
      "created": function created(_created) {
        return _vm.onCreated(_created);
      },
      "reset": _vm.reset,
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.DA
    }
  }, [_c('partner-report-initialize-dialog-from-da', {
    attrs: {
      "step": _vm.step,
      "reportDto": _vm.reportDto
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "update": function update(v) {
        return _vm.url = v;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.SEND
    }
  }, [_vm.step === _vm.PagesEnum.SEND ? _c('partner-report-initialize-dialog-send', {
    attrs: {
      "step": _vm.step,
      "url": _vm.url
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": _vm.close
    }
  }) : _vm._e()], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.SUCCESS,
      "step": _vm.step
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      }
    }
  }, [_c('partner-report-initialize-dialog-success', {
    attrs: {
      "step": _vm.step,
      "url": _vm.url
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.EMPTY_SUCCESS,
      "step": _vm.step
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      }
    }
  }, [_c('partner-report-initialize-dialog-empty-success', {
    attrs: {
      "step": _vm.step,
      "tickets": _vm.createdTickets
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": _vm.close
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.PagesEnum.CREATE_TICKET,
      "step": _vm.step
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      }
    }
  }, [_vm.PagesEnum.CREATE_TICKET ? _c('partner-report-initialize-dialog-create-ticket', {
    attrs: {
      "step": _vm.step,
      "report": _vm.createdReport
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "close": function close($event) {
        _vm.dialog = false;
      },
      "created": _vm.onTicketCreated
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }