var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-10"
  }, [_c('v-form', [_c('v-card-title', [_c('v-col', [_c('h1', {
    staticClass: "title"
  }, [_vm._v("Neuen Hersteller anlegen:")])])], 1), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Herstellername *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer, "name", $$v);
      },
      expression: "manufacturer.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Url zum Herstellerlogo *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.logoUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer, "logoUrl", $$v);
      },
      expression: "manufacturer.logoUrl"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Straße *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer.address, "street", $$v);
      },
      expression: "manufacturer.address.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Postleizahl *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer.address, "zip", $$v);
      },
      expression: "manufacturer.address.zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Stadt *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer.address, "city", $$v);
      },
      expression: "manufacturer.address.city"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Staat *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer.address, "state", $$v);
      },
      expression: "manufacturer.address.state"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "E-Mail *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer.contact, "email", $$v);
      },
      expression: "manufacturer.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Telefon *",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.manufacturer.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturer.contact, "phone", $$v);
      },
      expression: "manufacturer.contact.phone"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "buttonContainer"
  }, [_c('v-btn', {
    staticStyle: {
      "margin": "auto"
    },
    on: {
      "click": _vm.createManufacturer
    }
  }, [_vm._v(" Neuen Hersteller Anlegen ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }