var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "wrapper"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.template ? _c('v-card', {
    staticClass: "mb-n6",
    attrs: {
      "flat": ""
    }
  }, [_c('m-header', {
    attrs: {
      "title": _vm.template.meta.title,
      "subtitle": _vm.template.meta.description,
      "chips": _vm.chips,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('template-detail-body-card', {
    attrs: {
      "partnerId": _vm.partnerId,
      "template": _vm.template
    }
  })], 1)], 1) : _c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "fluid": "",
      "flat": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
      "max-height": "350px",
      "contain": ""
    }
  }), _c('v-row', {
    attrs: {
      "align": "center pt-8"
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('template-detail-create-dialog', {
    attrs: {
      "partnerId": _vm.partnerId
    },
    on: {
      "onCreated": _vm.refresh
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.template ? _c('template-detail-meta-update-dialog', {
    ref: "metaUpdate",
    attrs: {
      "meta": _vm.template.meta,
      "partnerId": _vm.partnerId,
      "isPublic": _vm.template.isPublic,
      "templateKey": _vm.template.key
    }
  }) : _vm._e(), _vm.template ? _c('template-detail-content-update-dialog', {
    ref: "contentUpdate",
    attrs: {
      "content": _vm.template.content,
      "partnerId": _vm.partnerId,
      "templateKey": _vm.template.key,
      "language": _vm.template.meta.language
    }
  }) : _vm._e(), _vm.template ? _c('template-detail-language-dialog', {
    ref: "languageOptions",
    attrs: {
      "template": _vm.template,
      "partnerId": _vm.partnerId
    }
  }) : _vm._e(), _c('template-detail-delete-dialog', {
    ref: "deleteDialog",
    attrs: {
      "partnerId": _vm.partnerId
    },
    on: {
      "onDelete": _vm.refresh
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }