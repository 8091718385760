var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-text-field', {
    attrs: {
      "type": "string",
      "label": _vm.$t('components.template.detail.create.titleLabel'),
      "outlined": ""
    },
    model: {
      value: _vm._meta.title,
      callback: function callback($$v) {
        _vm.$set(_vm._meta, "title", $$v);
      },
      expression: "_meta.title"
    }
  }), _vm.showLanguage ? _c('v-select', {
    attrs: {
      "type": "string",
      "items": _vm.availableLanguages,
      "label": _vm.$t('language'),
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " ")])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " ")])];
      }
    }], null, false, 3376209916),
    model: {
      value: _vm._meta.language,
      callback: function callback($$v) {
        _vm.$set(_vm._meta, "language", $$v);
      },
      expression: "_meta.language"
    }
  }) : _vm._e(), _c('v-combobox', {
    attrs: {
      "label": _vm.$t('components.template.detail.create.categoriesLabel'),
      "multiple": "",
      "chips": "",
      "outlined": ""
    },
    model: {
      value: _vm._meta.categories,
      callback: function callback($$v) {
        _vm.$set(_vm._meta, "categories", $$v);
      },
      expression: "_meta.categories"
    }
  }), _c('v-textarea', {
    attrs: {
      "type": "string",
      "label": _vm.$t('components.template.detail.create.descriptionLabel'),
      "outlined": "",
      "auto-grow": ""
    },
    model: {
      value: _vm._meta.description,
      callback: function callback($$v) {
        _vm.$set(_vm._meta, "description", $$v);
      },
      expression: "_meta.description"
    }
  }), !_vm.partnerId ? _c('v-select', {
    attrs: {
      "items": [true, false],
      "type": "boolean",
      "label": _vm.$t('components.template.detail.create.isPublicLabel'),
      "outlined": ""
    },
    model: {
      value: _vm._isPublic,
      callback: function callback($$v) {
        _vm._isPublic = $$v;
      },
      expression: "_isPublic"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }