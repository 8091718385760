var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    style: _vm.block ? 'width:100%' : ''
  }, [_vm.icon ? _c('v-btn', {
    staticClass: "ma-1",
    attrs: {
      "icon": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.createDialog = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-table-arrow-left")])], 1) : _vm._e(), _c('v-btn', {
    staticClass: "ma-1",
    style: _vm.block ? 'width:100%' : '',
    attrs: {
      "color": _vm.color,
      "block": _vm.block,
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.createDialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")]), _c('v-dialog', {
    model: {
      value: _vm.createDialog,
      callback: function callback($$v) {
        _vm.createDialog = $$v;
      },
      expression: "createDialog"
    }
  }, [_c('card', {
    attrs: {
      "title": _vm.$t(_vm.title),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.downloadExample
          }
        }, [_vm._v(_vm._s(_vm.$t("components.utility.CsvImportDialog.download")))]), _c('v-btn', {
          attrs: {
            "icon": "",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.confirmCreation
          }
        }, [_c('v-icon', [_vm._v("mdi-content-save")])], 1), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.createDialog = false;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-file-input', {
    staticClass: "mt-2",
    attrs: {
      "accept": ".csv",
      "placeholder": _vm.$t('components.utility.CsvImportDialog.upload'),
      "label": "CSV",
      "loading": _vm.loading,
      "multiple": false,
      "outlined": ""
    },
    on: {
      "change": _vm.change
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }), _vm.items.length > 0 ? _c('table-wrapper', {
    staticClass: "mt-n2",
    attrs: {
      "hideTableHeader": true,
      "allItems": _vm.items,
      "headers": _vm.header,
      "selectedItems": _vm.selected
    },
    on: {
      "removeItem": _vm.removeItem,
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }