var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mx-4",
    attrs: {
      "max-width": "1200",
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h3', [_vm._v("Hersteller")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', [_c('v-btn', {
    staticClass: "mx-2",
    on: {
      "click": _vm.newManufactuer
    }
  }, [_vm._v(" Hinzufügen ")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "flat": "",
      "max-width": "250"
    }
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.manufacturerList,
      "search": _vm.search,
      "no-data-text": "Keine Hersteller gefunden",
      "loading-text": "Hersteller werden geladen..."
    },
    on: {
      "click:row": _vm.handleClickedRow
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }