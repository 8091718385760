var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "title"
  }, [_vm._v("Dokument der Maschine zuordnen.")])]), _c('v-card-subtitle', [_c('span', [_vm._v(_vm._s(_vm.selectedMachine.machineName) + " (" + _vm._s(_vm.selectedMachine.variante) + ")")])]), _c('v-card-text', [_c('p', [_vm._v("Wählen Sie das Dokument, welches Sie der Maschine zuordnen wollen, aus.")]), _vm.selectedDocument.name ? [_c('v-card-text', [_c('v-chip', [_vm._v("Ausgewählt: " + _vm._s(_vm.selectedDocument.name))])], 1)] : _vm._e(), _c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchAssignableMachineAttachment,
      callback: function callback($$v) {
        _vm.searchAssignableMachineAttachment = $$v;
      },
      expression: "searchAssignableMachineAttachment"
    }
  })], 2), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.MachineAttachmentHeaders,
      "search": _vm.searchAssignableMachineAttachment,
      "items": _vm.assignableMachineAttachment,
      "loading-text": "Dokumente werden geladen...",
      "no-data-text": "Keine Dokumente zum hinzufügen."
    },
    on: {
      "click:row": _vm.selectMachineAttachmentToAdd
    }
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Abbrechen ")]), _c('v-btn', {
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.addDocumentToMachine
    }
  }, [_vm._v(" Zuordnen ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }