var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('user-administration-list-paginated', {
          attrs: {
            "loadingUser": _vm._loadingSelectedUserOrBanking
          },
          on: {
            "update:loadingUser": function updateLoadingUser($event) {
              _vm._loadingSelectedUserOrBanking = $event;
            },
            "update:loading-user": function updateLoadingUser($event) {
              _vm._loadingSelectedUserOrBanking = $event;
            }
          },
          model: {
            value: _vm.selectedUser,
            callback: function callback($$v) {
              _vm.selectedUser = $$v;
            },
            expression: "selectedUser"
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_vm.selectedUser ? _c('user-administration-detail', {
          attrs: {
            "selectedUser": _vm.selectedUser,
            "loading": _vm.loadingSelectedUser
          },
          on: {
            "bankingLoading": _vm.setBankingLoading
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }