var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "machineSelection"
  }, [_c('TopToolbarLogo', {
    attrs: {
      "src": _vm.partner.settings.logoUrl,
      "elevation": 1
    }
  }), _c('MachineSelectionCard', {
    staticClass: "content"
  }), _c('DocphantFooter', {
    attrs: {
      "partner": _vm.partner
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }