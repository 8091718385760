import { render, staticRenderFns } from "./PartnerReportInitializeDialogSuccess.vue?vue&type=template&id=dacb31b0&"
import script from "./PartnerReportInitializeDialogSuccess.vue?vue&type=script&lang=ts&"
export * from "./PartnerReportInitializeDialogSuccess.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VIcon,VImg,VSpacer})
