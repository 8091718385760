var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": _vm.$t('components.partner.UserAdministrationDetailBanking.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.bankingLoading ? _c('portal-banking-dialog', {
          attrs: {
            "userId": _vm.userId,
            "partnerId": _vm.partnerId
          },
          on: {
            "bankingChanged": _vm.updateBanking
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.bankingLoading ? _c('v-card-text', [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1) : !(_vm.banking.bank && _vm.banking.iban && _vm.banking.name) ? _c('v-card-text', [_c('latest-entries-card-empty')], 1) : _c('v-card-text', [_vm.banking.name ? _c('span', [_vm._v(" " + _vm._s(_vm.banking.name) + " "), _c('br')]) : _vm._e(), _vm.banking.bank ? _c('span', [_vm._v(" " + _vm._s(_vm.banking.bank) + " "), _c('br')]) : _vm._e(), _vm.banking.iban ? _c('span', [_vm._v(" " + _vm._s(_vm.banking.iban) + " "), _c('br')]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }